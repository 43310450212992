import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-audio-wrapper',
  templateUrl: './audio-wrapper.component.html',
  styleUrls: ['./audio-wrapper.component.scss'],
})
export class AudioWrapperComponent implements OnInit {

  // https://stackoverflow.com/questions/39366981/angular-2-viewchild-in-ngif
  public audioRef: ElementRef;
  @ViewChild('theAudioPlayer')
  set content(content: ElementRef) {
    // For some reason this code is called sometimes with content = undefined...
    if (content) {
      this.audioRef = content;
      this.logger.debug('audioRef is', this.audioRef);
      this.logger.debug('audioRef.currentTime', this.audioRef.nativeElement.currentTime);
      this.logger.debug('audioRef.paused', this.audioRef.nativeElement.paused);
      this.audioCurrentTime = 0;
      // Give the audio time to load before asking for its duration.
      setTimeout(() => {
        // this.logger.debug('audioRef.duration', this.audioRef.nativeElement.duration);
        this.audioDuration = this.audioRef.nativeElement.duration;
      }, 500);
    }
  }

  @Input() src: string;
  public audioCurrentTime = 0;
  public audioPlaying = false;
  public audioDuration = 0;


  constructor(
    private logger: LoggerService,
  ) { }

  ngOnInit() {
    this.logger.debug('audio-wrapper: src = ', this.src);
  }

}
