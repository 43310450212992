import { NgModule } from '@angular/core';
import { AudioWrapperComponent } from '../components/audio-wrapper/audio-wrapper.component';
import { HeaderFabComponent } from '../components/header-fab/header-fab.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AudioWrapperComponent,
    HeaderFabComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    RouterModule,
  ],
  exports: [
    AudioWrapperComponent,
    HeaderFabComponent,
  ],
})
export class SharedModule {}
