import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// NOTE:  We don't have a default route since the app component will navigate
//        to the home page after the app is initialized
const routes: Routes = [
  { path: '', redirectTo: '/webpage', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'gallery', loadChildren: './pages/gallery/gallery.module#GalleryPageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'image-pack-select', loadChildren: './pages/image-pack-select/image-pack-select.module#ImagePackSelectPageModule' },
  { path: 'language-select', loadChildren: './pages/language-select/language-select.module#LanguageSelectPageModule' },
  { path: 'webpage', loadChildren: './pages/webpage/webpage.module#WebpagePageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
