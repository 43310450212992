import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { fileWriteJson, fileReadJson, writeBlobToFile, removeAllDataFiles } from '../shared/code';
import { Plugins } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { LoggerService } from '../services/logger.service';
import { MiscUtilitiesService } from './misc-utilities.service';

const { Network } = Plugins;

export interface LanguagePackInfo {
  latestVersion: number;       // most up-to-date version available
  installedVersion?: number;    // 0 => not installed.
  abbr: string;                // e.g., EN, FR, etc.
  nameInLanguage: string;      // "English"
  otherNames: {                    // one object for each other language.
    abbr: string;                  // e.g., DE
    nameInThisLanguage: string;    // e.g., German
  }[];
}

export interface AvailableAndOrInstalledLanguage {
  abbr: string;
  installed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private installedLanguages: LanguagePackInfo[] = [];
  private availableLanguages: LanguagePackInfo[];

  // Declare a variable used for timing out promise requests
  private onlineRetrievalTimeout = 30000; // in milliseconds

  constructor(
    private db: AngularFireStorage,
    private http: HttpClient,
    private toastCtrl: ToastController,
    private logger: LoggerService,
    private utilities: MiscUtilitiesService,
  ) {
    this.logger.entry("LanguageService.constructor()");
  }

  /**
   * load languages.json, which contains all information about the installed
   * language packs.  Read the file from /Data. If it isn't there, then this
   * must be the first time the app is being run, so:
   *  o copy the file from assets/ to /Data/
   *  o copy the EN files from assets/content/EN/ to /Data/EN-*.
   *  o copy the EN audio files to /Data/EN-*.
   *  o read the file from /Data/languages.json.
   */
  public async loadAllData() {

    this.logger.entry("LanguageService.loadAllData()");
    return new Promise(resolve => {

      // Read /Data/languages.json, if possible, to get the list of languages we have
      // installed.
      this.logger.debug('language.service.loadAllData: calling fileRead');
      fileReadJson('languages.json').then(res => {
        this.installedLanguages = JSON.parse(res.data) as LanguagePackInfo[];
        this.logger.debug('🐯🐯🐯: languages is ', this.installedLanguages);
        resolve();
      }).catch(async (err) => {

        // languages.json does not exist on /Data: need to copy from assets/languages.json.
        // this must be the first time the app is running.
        this.logger.info('languages.json is not in /Data: copying EN files there from assets');
        await this.copyJsonFileToDataPartition('assets/languages.json', 'languages.json');
        await this.copyJsonFileToDataPartition('assets/content/EN/apptext.json', 'EN-apptext.json');
        await this.copyJsonFileToDataPartition('assets/content/EN/chapters.json', 'EN-chapters.json');
        this.copyAssetAudioFilesToData();

        await fileReadJson('languages.json').then(res2 => {
          this.installedLanguages = JSON.parse(res2.data) as LanguagePackInfo[];
          this.logger.debug('🤖🤖🤖 this.languages set to ', this.installedLanguages);
        }).catch(_ => this.logger.error('***** error in fileRead or parsing results'));
        resolve();
      });
    });
  }

  getLanguageAbbrs() {
    this.logger.entry("LanguageService.getLanguageAbbrs()");
    return this.installedLanguages.map(lang => lang.abbr);
  }

  // Return the name of the target language in the language of the selected language.
  getLanguageNameInSelectedLanguage(selectedLangAbbr: string, targetLangAbbr: string) {
    //spewage    this.logger.entry("LanguageService.getLanguageNameInSelectedLanguage()");
    if (selectedLangAbbr === targetLangAbbr) {
      return this.getLanguageName(selectedLangAbbr);
    }
    const res = this.availableLanguages.find(lang => lang.abbr === selectedLangAbbr);
    // this.logger.debug('getLNISL: res = ', res);
    return res.otherNames.find(lang => lang.abbr === targetLangAbbr).nameInThisLanguage;
  }

  // Return the language name in the selected language for the given language.
  getLanguageName(selectedLangAbbr: string) {
    //spewage    this.logger.entry("LanguageService.getLanguageName()");
    if (this.availableLanguages) {
      return this.availableLanguages.find(lang => lang.abbr === selectedLangAbbr).nameInLanguage;
    }
    return this.installedLanguages.find(lang => lang.abbr === selectedLangAbbr).nameInLanguage;
  }

  /**
   * get the available_languages.json file from firebase storage and return it.
   */
  public async getAvailableLanguagesOnline(): Promise<LanguagePackInfo[]> {
    this.logger.entry("LanguageService.getAvailableLanguagesOnline()");
    const status = await Network.getStatus();
    this.logger.debug('network status = ', status);
    if (!status.connected) {
      const toast = await this.toastCtrl.create({
        message: '<img src="assets/icon/40h/no-wifi.png"> Not connected to network',
        duration: 3000,
      });
      toast.present();
      return Promise.resolve(this.installedLanguages);  // nothing new can be gotten online
    } else {
      return new Promise<LanguagePackInfo[]>(resolve => {
        this.logger.debug('😊 getAvailableLanguagesOnline: after checking network status, getting from firebase');
        const ref = this.db.storage.ref('available_languages.json');
        ref.getDownloadURL().then(url => {
          this.http.get(url).toPromise().then((resp: LanguagePackInfo[]) => {
            this.logger.debug('💀💀💀💀 available_languages on remote storage: ', resp);
            resolve(resp);
          });
        });
      });
    }
  }

  public async computeLanguagesList() {
    this.logger.entry("LanguageService.computeLanguagesList()");
    this.availableLanguages = await this.getAvailableLanguagesOnline();

    const availAndInstalledLanguages = this.availableLanguages.map(l => {
      return {
        abbr: l.abbr,
        installed: this.isInstalled(l.abbr),
      };
    });
    return availAndInstalledLanguages;
  }


  /**
   * compare the available languages in firebase storage to those that are already
   * installed, building and returning a list of languages that haven't been
   * installed.
   */
  public async computeNewLanguages(): Promise<string[]> {
    this.logger.entry("LanguageService.computeNewLanguages()");
    this.availableLanguages = await this.getAvailableLanguagesOnline();
    const res: string[] = [];
    for (const langOnline of this.availableLanguages) {
      if (this.notInInstalledLanguages(langOnline.abbr) ||
        this.updateToInstalledLanguageExists(langOnline.abbr, langOnline.latestVersion)) {
        this.logger.warn(`🎃 found that ${langOnline.abbr} is not installed or can be updated`);
        res.push(langOnline.abbr);
      }
    }
    this.logger.info('computeNewLanguages: returning ', res);
    return res;
  }

  isInstalled(langOnline: string): boolean {
    this.logger.entry("LanguageService.isInstalled()");
    return this.installedLanguages.find(l => l.abbr === langOnline) !== undefined;
  }

  private notInInstalledLanguages(langOnline: string): boolean {
    this.logger.entry("LanguageService.notInInstalledLanguages()");
    return this.installedLanguages.find(l => l.abbr === langOnline) === undefined;
  }

  private updateToInstalledLanguageExists(langOnline: string, langOnlineVersion: number) {
    this.logger.entry("LanguageService.updateToInstalledLanguageExists()");
    // tslint:disable-next-line:max-line-length
    return this.installedLanguages.find(l => l.abbr === langOnline && l.installedVersion && l.installedVersion < langOnlineVersion) !== undefined;
  }

  private copyJsonFileToDataPartition(from: string, to: string) {
    this.logger.entry("LanguageService.copyJsonFileToDataPartition()");
    return new Promise(resolve => {
      this.logger.debug(`💯copyJsonFile: from ${from} to ${to}`);
      this.http.get(from)
        .toPromise().then(async data => {
          this.logger.debug('💯copyJsonFile: data = ', JSON.stringify(data));
          await fileWriteJson(to, data);
          this.logger.debug('💯copyJsonFile: DONE copying file ', from);
          resolve();
        });
    });
  }

  /**
   * download a language's apptext.json, chapters.json, and audio files from
   * firebase storage into /Data.  Also, merge the new info about the
   * downloaded language into the languages.json file.
   */
  async downloadLanguageFromOnlineStorage(lang: string): Promise<any> {
    this.logger.entry("LanguageService.downloadLanguageFromOnlineStorage()");

    return new Promise((resolve, reject) => {
      // Create an array of racing promises - the race is between a timeout and getting
      // the data from the net.  We then collect all the promises in a Promise.all to
      // make sure they all finished before reporting success. 
      const onLineContent = [
        this.utilities.promiseTimeout(this.onlineRetrievalTimeout, this.getLanguageJsonFilesFromOnline(lang)),
        this.utilities.promiseTimeout(this.onlineRetrievalTimeout, this.getAudioFilesFromOnline(lang)),
      ];

      Promise.all(onLineContent).then(async () => {
        this.logger.debug("Received all downloaded language data from online storage");
        this.logger.exit("LanguageService.downloadLanguageFromOnlineStorage()");

        // Online data retrieval was successful.  Write out a new languages JSON file
        await this.createNewLanguagesJsonFile(lang);
        resolve();
      }).catch((error) => {
        this.logger.error("Error getting the data from online storage");
        reject();
      });
    });
  }

  /**
   *  Create new languages.json file and save to Data.
   */
  createNewLanguagesJsonFile(lang: string): Promise<any> {
    this.logger.entry("LanguageService.createNewLanguagesJsonFile()");

    return new Promise((resolve) => {
      // this.languages has the old info, in the correct format.
      // We have to do these things:
      // 1. For the already-installed languages, we have to update the otherNames
      //  property to include the name for this newly downloaded language.
      // 2. find the block that refers to the just-downloaded language
      //  and update this.languages with that info.
      // 3. Then, finally write languages.json in /Data.

      const newLangInfo = this.availableLanguages.find(l => l.abbr === lang);

      // Step 1. For each language we have installed, find the record from online and
      // copy the otherNames part.
      for (const oldLangs of this.installedLanguages) {
        const newRecord = this.availableLanguages.find(l => l.abbr === oldLangs.abbr);
        // We could just copy the otherNames for the one new language, but getting them
        // all does not hurt and is a lot easier... :-).
        oldLangs.otherNames = newRecord.otherNames;
      }

      // Step 2.
      const newLangPackInfo: LanguagePackInfo = {
        abbr: lang,
        installedVersion: newLangInfo.latestVersion,
        latestVersion: newLangInfo.latestVersion,
        nameInLanguage: newLangInfo.nameInLanguage,
        otherNames: newLangInfo.otherNames,
      };
      this.installedLanguages.push(newLangPackInfo);
      this.logger.debug('👺👺👺 after download, languages now is:', this.installedLanguages);

      // Step 3.
      fileWriteJson('languages.json', this.installedLanguages).then(() => {
        resolve();
      });
    });
  }

  /**
   * Download a language's apptext.json and chapters.json files and
   * store in the Data folder.
   */
  private getLanguageJsonFilesFromOnline(lang: string) {
    this.logger.entry("LanguageService.getLanguageJsonFilesFromOnline()");
    for (const fileName of ['apptext.json', 'chapters.json']) {
      const fileToGet = lang + '/' + fileName;
      const fileToWrite = lang + '-' + fileName;
      const pathReference = this.db.storage.ref(fileToGet);
      this.logger.debug('pathReference = ', pathReference);
      pathReference.getDownloadURL().then(url => {
        this.logger.debug('🚚🚚🚚🚚🚚 url is', url);
        this.http.get(url).toPromise().then((resp) => {
          fileWriteJson(fileToWrite, resp);
          this.logger.debug('done calling fileWrite to ', fileToWrite);
        }, error => {
          this.logger.error('error from subscribe: ', error);
        });
      }).catch(j => {
        this.logger.error('ERROR: ', j);
      });
    }
  }

  private getAudioFilesFromOnline(lang: string): Promise<any> {
    this.logger.entry("LanguageService.getAudioFilesFromOnline()");
    return new Promise((resolve, reject) => {
      for (let i = 1; i <= 20; i++) {
        const fileToGet = `${i}.mp3`;
        const pathReference = this.db.storage.ref(lang + '/' + fileToGet);
        this.logger.debug('pathReference = ', pathReference);
        pathReference.getDownloadURL().then(url => {
          this.logger.debug('🚚🚚🚚🚚🚚 url is', url);
          this.http.get(url, {
            responseType: 'blob',
          }).toPromise().then((resp) => {
            writeBlobToFile(lang + '-' + fileToGet, resp);
            this.logger.debug('done calling writeBlobToFile ', lang + '-' + fileToGet);
            if (i === 20) resolve();
          }, error => {
            this.logger.error('error from subscribe: ', error);
            reject();
          });
        }).catch(j => {
          this.logger.error('ERROR: ', j);
          reject();
        });
      }
    });
  }

  /**
   * Copy the EN audio files from assets/audio/EN to /Data so that
   * all language audio files are read from there.
   */
  private copyAssetAudioFilesToData() {
    this.logger.entry("LanguageService.copyAssetAudioFilesToData()");
    for (let i = 1; i <= 20; i++) {
      this.http.get(`assets/audio/EN/${i}.mp3`, {
        responseType: 'blob'
      }).toPromise().then(data => {
        this.logger.debug(`--- copying assets/audio/EN/${i}.mp3 to /Data`);
        writeBlobToFile(`EN-${i}.mp3`, data);
      });
    }
  }

  // for testing only
  async eraseAllFiles() {
    this.logger.entry("LanguageService.eraseAllFiles()");
    try {
      await removeAllDataFiles();

      // Need to update the installedLanguages array
      this.installedLanguages = [];

    } catch (e) {
      // ignore errors.
    }
  }

}
