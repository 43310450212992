import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../app/shared/shared.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { credentials } from './credentials';
import { SharedErrorHandler } from './shared/shared-error-handler';
import * as Hammer from 'hammerjs';

import { AppVersion } from '@ionic-native/app-version/ngx';

// Custom Hammer Configuration
// Followed Ionic 4 example from Josh Morony: https://www.youtube.com/watch?v=TdORJC-J1gg
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

// For other gestures, see the HammerJS API at: https://hammerjs.github.io/getting-started/
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    'press': {
      time: 500
    },
    'swipe': {
      threshold: 200,
      direction: Hammer.DIRECTION_ALL
    }
  }
}

const ERROR_HANDLER = SharedErrorHandler;

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    AngularFireModule.initializeApp(credentials),
    AngularFirestoreModule,
    AngularFireStorageModule,
  ],
  providers: [
    AppVersion,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
//TBD - way to catch errors in the app and display on an Alert Control    { provide: ErrorHandler, useClass: ERROR_HANDLER }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
