import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MiscUtilitiesService } from 'src/app/services/misc-utilities.service';
import { SettingsControllerService } from 'src/app/services/settings-controller.service';

@Component({
  selector: 'app-header-fab',
  templateUrl: './header-fab.component.html',
  styleUrls: ['./header-fab.component.scss'],
})
export class HeaderFabComponent implements OnInit {

  // Route to follow after clicking the fab button
  @Input() whereToGo = '';

  // Default icon for the fab button is the back arrow
  @Input() fabIcon = 'ios-arrow-back';

  // Output event when the user clicks the fab button
  @Output() public onClick: EventEmitter<any> = new EventEmitter();

  public isIosDevice = false;
  public rtlDirection = false;

  constructor(
    private utilitiesCtrl: MiscUtilitiesService,
    private settingsCtrl: SettingsControllerService,
  ) {
    // Check if this is an iOS device
    this.isIosDevice = this.utilitiesCtrl.isAnIosDevice;

    // Register for changes in text direction 
    //   in RTL we need to switch the back arrow to point forward, to the right
    this.settingsCtrl.directionSubj.subscribe(dir => {
      if (this.fabIcon != 'menu') {
        if (dir === 'rtl') {
          this.rtlDirection = true;
          this.fabIcon = 'ios-arrow-forward';
        } else {
          this.rtlDirection = false;
          this.fabIcon = 'ios-arrow-back';
        }
      }
    });
  }

  ngOnInit() {
  }

  // Emit an event when the fab button is clicked.  The parent can figure out
  // what they want to do with it
  fabClick(): void {
    this.onClick.emit();
  }

}
