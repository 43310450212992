import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomController } from '@ionic/angular';

interface Theme {
  name: string;
  styles: ThemeStyle[];
}

interface ThemeStyle {
  themeVariable: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeSwitcherService {

  private themes: Theme[] = [];
  private currentTheme: number = 0;

  constructor(private domCtrl: DomController, @Inject(DOCUMENT) private document) {
    this.themes = [
      // Using this site to convert: https://ionicframework.com/docs/pt/theming/color-generator/
      // Day based on 
      // --ion-color-primary: #9e8eb0;
      // --ion-color-primary-rgb: 158,142,176;
      // --ion-color-primary-contrast: #000000;
      // --ion-color-primary-contrast-rgb: 0,0,0;
      // --ion-color-primary-shade: #8b7d9b;
      // --ion-color-primary-tint: #a899b8;
      {
        name: 'day',
        styles: [

          { themeVariable: '--ion-color-primary', value: '#9e8eb0' },
          { themeVariable: '--ion-color-primary-rgb', value: '158,142,176' },
          { themeVariable: '--ion-color-primary-contrast', value: '#000000' },
          { themeVariable: '--ion-color-primary-contrast-rgb', value: '0,0,0' },
          { themeVariable: '--ion-color-primary-shade', value: '#8b7d9b' },
          { themeVariable: '--ion-color-primary-tint', value: '#a899b8' },


          { themeVariable: '--ion-color-primary-opposite', value: '#614f71' },
          { themeVariable: '--ion-item-ios-background-color', value: '#ffffff' },
          { themeVariable: '--ion-item-md-background-color', value: '#ffffff' },
          { themeVariable: '--ion-tabbar-background-color', value: '#fff' },
          { themeVariable: '--ion-tabbar-ios-text-color-active', value: '#000000' },
          { themeVariable: '--ion-tabbar-md-text-color-active', value: '#000000' },
          { themeVariable: '--ion-background-color', value: 'white' },
          { themeVariable: '--ion-text-color', value: 'black' },
          { themeVariable: '--ion-text-color-rgb', value: '0,0,0' },
          { themeVariable: '--ion-overlay-background-color', value: 'white' },
        ]
      },
      // --ion-color-primary: #614f71;
      // --ion-color-primary-rgb: 97,79,113;
      // --ion-color-primary-contrast: #ffffff;
      // --ion-color-primary-contrast-rgb: 255,255,255;
      // --ion-color-primary-shade: #554663;
      // --ion-color-primary-tint: #71617f;
      {
        name: 'night',
        styles: [

          { themeVariable: '--ion-color-primary', value: '#614f71' },
          { themeVariable: '--ion-color-primary-rgb', value: '97,79,113' },
          { themeVariable: '--ion-color-primary-contrast', value: '#ffffff' },
          { themeVariable: '--ion-color-primary-contrast-rgb', value: '255,255,255' },
          { themeVariable: '--ion-color-primary-shade', value: '#554663' },
          { themeVariable: '--ion-color-primary-tint', value: '#71617f' },


          { themeVariable: '--ion-color-primary-opposite', value: '#9e8eb0' },
          { themeVariable: '--ion-item-ios-background-color', value: '#313131' },
          { themeVariable: '--ion-item-md-background-color', value: '#313131' },
          { themeVariable: '--ion-tabbar-background-color', value: '#000' },
          { themeVariable: '--ion-tabbar-ios-text-color-active', value: '#ffffff' },
          { themeVariable: '--ion-tabbar-md-text-color-active', value: '#ffffff' },
          { themeVariable: '--ion-background-color', value: '#313131' },
          { themeVariable: '--ion-text-color', value: 'white' },
          { themeVariable: '--ion-text-color-rgb', value: '255,255,255' },
          { themeVariable: '--ion-overlay-background-color', value: 'darkgray' },
        ]
      }
    ]

  }

  setTheme(name): void {

    let theme = this.themes.find(theme => theme.name === name);

    this.domCtrl.write(() => {

      theme.styles.forEach(style => {
        document.documentElement.style.setProperty(style.themeVariable, style.value);
      });

    });

  }

}

