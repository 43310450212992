import { FilesystemDirectory, Plugins, FilesystemEncoding } from '@capacitor/core';
import { LoggerService } from '../services/logger.service';

const { Filesystem } = Plugins;


// Needed a way to get access to the LoggerService without having a constructor
// to inject it into.  That's why I needed to create the initializeLogger
// method that is called from app.components.ts
let logger: any;

export const initializeLogger = (loggerService: LoggerService) => {
  logger = loggerService;
}

export const fileRead = async (fname: string) => {
  const contents = await Filesystem.readFile({
    path: fname,
    directory: FilesystemDirectory.Data,
  });
  return contents;
};

export const fileReadJson = async (fname: string) => {
  const contents = await Filesystem.readFile({
    path: fname,
    directory: FilesystemDirectory.Data,
    encoding: FilesystemEncoding.UTF8,
  });
  return contents;
};

export const writeBlobToFile = (to, data: Blob) => {

  convertBlobToBase64(data).then((str: string) => {
    Filesystem.writeFile({
      data: str,
      path: to,
      directory: FilesystemDirectory.Data,
    });
  });
};

const convertBlobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

export const fileWrite = (fname: string, data: string) => {
  try {
    Filesystem.writeFile({
      path: fname,
      data,
      directory: FilesystemDirectory.Data,
    });
  } catch (e) {
    logger.error('Unable to write file', e);
  }
};

export const fileWriteJson = async (fname: string, data: any) => {
  try {
    logger.debug('fileWriteJson: callign writeFile', fname);
    await Filesystem.writeFile({
      path: fname,
      data: JSON.stringify(data),
      directory: FilesystemDirectory.Data,
      encoding: FilesystemEncoding.UTF8,
    });
    logger.debug('fileWriteJson: done writeFile', fname);
  } catch (e) {
    logger.error('Unable to write file', e);
  }
};

export const getDirectoryFilePath = (dirName: string) => {
  return Filesystem.getUri({
    directory: FilesystemDirectory.Data,
    path: dirName,
  });
};

export const removeAllDataFiles = () => {
  for (const f of ['EN-apptext.json', 'EN-chapters.json', 'languages.json']) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: f,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `AR-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `BG-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `DE-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `EN-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `FR-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `IT-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `RU-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `TR-${n}.mp3`,
    });
  }
  for (let n = 1; n <= 20; n++) {
    Filesystem.deleteFile({
      directory: FilesystemDirectory.Data,
      path: `UA-${n}.mp3`,
    });
  }
  // return Filesystem.readdir({
  //   path: '',
  //   directory: FilesystemDirectory.Data,
  // });
};
