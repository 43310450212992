import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DOCUMENT } from '@angular/platform-browser';
import { C2CObserver, Direction } from '../shared/types';
import { fileReadJson } from '../shared/code';
import { LoggerService, LogLevel } from '../services/logger.service';
import { BehaviorSubject } from 'rxjs';
import { ThemeSwitcherService } from './theme-switch.service';

export interface AppText {
  forLanguage: string;
  direction: Direction;   // the direction this language is displayed.

  // Translations for these displayed elements.
  learn: string;
  gallery: string;
  about: string;
  settings: string;
  share: string;
  credits: string;
  language: string;
  wanderingSonMode: string;
  listenMode: string;
  lightMode: string;
  automatic: string;
  manual: string;
  off: string;
  day: string;
  night: string;
  cancel: string;
  ok: string;
  imagePack: string;
  original: string;
  mosaic: string;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsControllerService {

  // App started flag
  private appStartedFlag = true;

  // Learn Mode flag
  private learnMode = false;

  // Translations for elements for the selected language.
  public appText: AppText = undefined;
  public appVersion = '4.0 - pre-alpha';

  // Define behavior subjects 
  public appTextSubj: BehaviorSubject<AppText> =
    new BehaviorSubject<AppText>(undefined);
  public languageSubj: BehaviorSubject<string> =
    new BehaviorSubject<string>(undefined);
  public directionSubj: BehaviorSubject<Direction> =
    new BehaviorSubject<Direction>(undefined);

  // User settings with defaults
  language = 'EN';
  listenMode = 'manual';
  lightMode = 'day';
  imagePack = 'original';
  wanderingSonMode = false;

  private observers: C2CObserver[] = [];
  private appTextArray: AppText[] = [];

  constructor(
    public http: HttpClient,
    public platform: Platform,
    public storage: Storage,
    public logger: LoggerService,
    public themeSwitcher: ThemeSwitcherService,
    // Next line thanks to:
    // https://angularfirebase.com/lessons/css-variables-in-ionic-4/
    // This is used to set some CSS styles for *all* elements in the DOM.
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.logger.entry("SettingsControllerService.constructor()");
  }

  /**
   * reads app text from JSON file provided in assets/content/-LANG-/ directory. After the JSON file
   * has been read once, it just returns the AppText instance variable, which is faster for
   * obvious reasons.
   */
  getAppText(): Promise<AppText> {
    this.logger.entry("SettingsControllerService.getAppText()");
    return new Promise(async (resolve, reject) => {
      if (this.appText) {
        // already loaded data - no need to read from file
        this.appTextSubj.next(this.appText);
        return resolve(this.appText);
      }

      // look in our list of languages we have already read in; if there, return it.  If not, read from JSON
      if (this.appTextArray.length > 0) {
        this.appText = this.appTextArray.find(at => at.forLanguage === this.language);
        this.logger.info("getAppText ===> newLang = ", this.appText);
        if (this.appText) {
          this.appTextSubj.next(this.appText);
          return resolve(this.appText);
        }
      }

      // data needs to be read from file
      this.logger.info('getAppText: looking for ' + this.language + '-apptext.json in /Data');
      await fileReadJson(this.language + '-apptext.json')
        .then(data => {
          this.appText = JSON.parse(data.data) as AppText;
          this.appTextArray.push(this.appText);
          this.appTextSubj.next(this.appText);
          return resolve(this.appText);
        })
        .catch(e => {
          this.logger.error('👾👾👾 getAppText: failure!: probably file not found: ' + e);
          return reject();
        });
    });
  }

  /**
   * this resets the appText to be undefined so that next time getAppText() is called in the app
   * it will read from json file again based on current language.
   */
  async resetAppText() {
    this.logger.entry("SettingsControllerService.resetAppText()");
    this.appText = undefined;
    this.getAppText().then(
      () => {

        // Notify all observers that the settings have changed.
        if (this.appText) {
          for (const obs of this.observers) {
            obs.notify();
          }
          this.logger.debug("settings-controller.resetAppText(): setting direction to " + this.appText.direction);
          this.setDirectionTo(this.appText.direction);
        }
      }, (error) => {
        this.logger.warn("settings-controller.resetAppText: appText not ready yet");
      });
  }

  /**
   * sets the global language of the app
   * @param language - EN, FR, TR, RU, UA, BG, AR, ARS
   */
  setLanguage(language: string) {
    this.logger.entry("SettingsControllerService.setLanguage()");
    this.language = language;
    this.storage.set('language', language);
    this.logger.debug('storing language in storage', language);
    this.languageSubj.next(language);
  }

  /**
   * sets the global listening mode
   * @param listenMode - auto, manual, off
   */
  setListenMode(listenMode: string) {
    this.logger.entry("SettingsControllerService.setListenMode()");
    this.listenMode = listenMode;
    this.storage.set('listenMode', listenMode);
    this.logger.debug('storing listenMode in storage', listenMode);
  }

  setLearnMode(mode: boolean) {
    this.logger.entry("SettingsControllerService.setLearnMode: " + mode);
    this.learnMode = mode;
  }

  getLearnMode(): boolean {
    return this.learnMode;
  }

  getImagePackName() {
    // this.logger.entry("SettingsControllerService.getImagePackName()");
    return this.appText[this.imagePack];
  }

  /**
   * sets the global light mode of the app
   * @param lightMode - day, night
   */
  setLightMode(lightMode: string) {
    this.logger.entry("SettingsControllerService.setLightMode()");
    this.lightMode = lightMode;
    this.storage.set('lightMode', lightMode);
    this.logger.debug('setting lightMode in storage to: ', lightMode);

    // Update the app light mode using CSS
    this.updateLightModeCssSettings(lightMode);
  }

  /**
   * toggles wandering son mode on the app (which restricts the slides that are visible to just the
   * last 5 or so)
   */
  setWanderingSonMode(wanderingSonMode: boolean) {
    this.logger.entry("SettingsControllerService.setWanderingSonMode()");
    this.logger.debug('setWanderingSM: set to ', wanderingSonMode);

    this.wanderingSonMode = wanderingSonMode;
    this.storage.set('wanderingSonMode', wanderingSonMode);
    // Notify all observers that the settings have changed.
    for (const obs of this.observers) {
      obs.notify();
    }
  }

  /**
   * setter method for image pack setting
   * @param imagePack - the image pack
   */
  setImagePack(imagePack: string) {
    this.logger.entry("SettingsControllerService.setImagePack()");
    this.imagePack = imagePack;
    this.storage.set('imagePack', imagePack);
  }

  /**
   * initialize sets the app settings to the values stored in the persistent storage
   */
  async getSettingsFromStorage() {
    this.logger.entry("SettingsControllerService.getSettingsFromStorage()");
    this.logger.debug('settingsController: before reading values from storage, values are:');
    this.logger.debug('\tlanguage', this.language);
    this.logger.debug('\tlistenMode', this.listenMode);
    this.logger.debug('\tlightMode', this.lightMode);
    this.logger.debug('\timagePack', this.imagePack);
    this.logger.debug('\twanderingSonMode', this.wanderingSonMode);

    // Query storage for initial settings.  If a setting hasn't been stored yet,
    // provide a default value.  
    this.imagePack = await this.storage.get('imagePack');
    if (!this.imagePack) this.imagePack = 'original';

    this.language = await this.storage.get('language');
    if (!this.language) this.language = 'EN';

    this.wanderingSonMode = await this.storage.get('wanderingSonMode');
    if (!this.wanderingSonMode) this.wanderingSonMode = false;

    this.listenMode = await this.storage.get('listenMode');
    if (!this.listenMode) this.listenMode = 'manual';

    this.lightMode = await this.storage.get('lightMode');
    if (!this.lightMode) this.lightMode = 'day';

    // Inform any behavior subject subscribers
    this.languageSubj.next(this.language);

    // Reset the app text based on storage settings
    this.resetAppText();

    // Need to update the light mode CSS settings initially
    this.updateLightModeCssSettings(this.lightMode);

    this.logger.debug("SettingsControllerService.getSettingsFromStorage(), completed storage retrieval.  Values are now: "
      + " imagePack: " + this.imagePack + " language: " + this.language
      + " wanderingSonMode: " + this.wanderingSonMode + " listenMode: " + this.listenMode
      + " lightMode: " + this.lightMode);
  }

  /* Set CSS styles based on the passed in light mode */
  updateLightModeCssSettings(lightMode) {
    this.logger.entry("SettingsControllerService.updateLightModeCssSettings()");
    this.logger.debug('updateLightModeCssSettings(): Setting CSS settings for lightMode: ' + lightMode);

    // added by Rick to use generic theme service
    if (lightMode === 'day') {
      this.themeSwitcher.setTheme('day');
    } else {
      this.themeSwitcher.setTheme('night');
    }

    // This was Vic's original code, but doesn't get enough of the necessary CSS variables.
    // we *COULD* have just expanded this code with all the extra variables, but
    // instead we created the more generic theme-service.  Perhaps a bit easier to manage; or not.

    // Thanks to:
    // https://angularfirebase.com/lessons/css-variables-in-ionic-4/
    // This sets CSS styles for *all* elements in the DOM.
    //
    // if (lightMode === 'day') {
    //   this.document.documentElement.style.cssText = `
    //     --ion-background-color: white;
    //     --ion-text-color: black;
    //     --ion-border-color: black;
    //     `;
    // } else {
    //   this.document.documentElement.style.cssText = `
    //     --ion-background-color: black;
    //     --ion-text-color: white;
    //     --ion-border-color: white;
    //     `;
  }


  addObserver(obs: C2CObserver) {
    this.observers.push(obs);
  }

  // Change the direction for the entire app
  setDirectionTo(dir: Direction) {
    // Let everyone know about the change in direction
    this.directionSubj.next(dir);
  }

  // The followiing two methods are used to determine whether this is the first 
  // time through the app
  appJustStarted() {
    return this.appStartedFlag;
  }

  resetAppStartedFlag() {
    this.appStartedFlag = false;
  }

  // for testing
  async eraseLocalStorage() {
    this.logger.entry("SettingsControllerService.eraseLocalStorage()");
    await this.storage.clear();
  }
}
