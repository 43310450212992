import { Component } from '@angular/core';

import { Platform, MenuController, Events } from '@ionic/angular';

import { Router } from '@angular/router';
import { SettingsControllerService, AppText } from './services/settings-controller.service';
import { LanguageService } from './services/language.service';
import { initializeLogger } from './shared/code';

import { Plugins } from '@capacitor/core';
import { LoggerService, LogLevel } from './services/logger.service';
import { MiscUtilitiesService } from './services/misc-utilities.service';
const { SplashScreen, StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  private appText: AppText;

  public menuSide: string = "end";
  public globalRtl: string = "ltr";

  constructor(
    private platform: Platform,
    public settingsCtrl: SettingsControllerService,
    private router: Router,
    private menuCtrl: MenuController,
    private langCtrl: LanguageService,
    private logger: LoggerService,
    public learnToggle: Events,
    public utilities: MiscUtilitiesService,
  ) {
    // Initialize the log level for the app
    this.logger.setCurrentLogLevel(LogLevel.All);

    // Initialize the logger in our utility code (no constructor injection)
    initializeLogger(this.logger);

    // Initialize our utilities service
    this.utilities.initializeUtilities();

    // Can't call this right away since logger wasn't ready beforehand
    this.logger.entry("AppComponent.constructor()");
    this.initializeApp();
    this.logger.exit("AppComponent.constructor()");

    // Subscribe to changes in appText to get text direction changes
    this.settingsCtrl.addObserver(this);
  }

  // Applying https://alligator.io/ionic/events/
  toggleLearn() {
    this.learnToggle.publish('toggle-text', 'true');
    this.menuCtrl.close();
  }

  async initializeApp() {

    this.logger.entry("AppComponent.initializeApp()");
    this.platform.ready().then(async () => {
      this.logger.info('initializeApp: platform ready: getting settings from storage');
      await this.settingsCtrl.getSettingsFromStorage();
      this.logger.info('initializeApp: got settings from storage: now loading all data');
      await this.langCtrl.loadAllData();
      this.logger.info('initializeApp: loaded all data: now getting app text');
      this.appText = await this.settingsCtrl.getAppText();
      this.logger.debug('app.component: initializeApp: appText', this.appText);
      this.logger.debug('app.component: hiding splash screen');

      // Use capacitor to hide the splash screen and status bar now that the 
      // app is initialized
      SplashScreen.hide().catch((err) => {
        this.logger.warn(err);
      });
      StatusBar.hide().catch((err) => {
        this.logger.warn(err);
      });

      // Now display home page.  By manually routing, we can ensure that the
      // storage data is available before initializing the rest of the app
      this.logger.debug('app.component: navigating to /home');
      // this.router.navigateByUrl('/home');
      this.logger.exit("AppComponent.initializeApp()");
    });
  }

  openPage(page: string) {
    this.logger.entry("AppComponent.openPage()");
    this.menuCtrl.close();
    this.router.navigateByUrl(page);
  }

  notify() {
    this.logger.entry("AppComponent.notify()");
    // AppText has changed.  Check the direction to see if we need to change
    // the location of the menu
    this.settingsCtrl.getAppText().then((appText) => {
      if (appText.direction === "rtl") {
        this.menuSide = "end";
      } else {
        this.menuSide = "start";
      }
      this.globalRtl = appText.direction;
      this.logger.debug("AppComponent.notify:  setting menuSide to " + this.menuSide);
    });
  }

  eraseSettings() {
    this.logger.entry("AppComponent.eraseSettings()");
    this.logger.info('Erasing all local storage settings; erasing EN, FR, AR, and DE language files.');
    this.settingsCtrl.eraseLocalStorage();
    this.langCtrl.eraseAllFiles();
  }

}
