import { ErrorHandler, Inject } from '@angular/core';
import { AlertController } from '@ionic/angular';


// This is an error handler that can be used to catch errors in the
// app without being connected to a debugger.  To activate the handler,
// un-comment the provider in app.module.ts.
export class SharedErrorHandler implements ErrorHandler {

  constructor (
    @Inject(AlertController) private alerts: AlertController,
  ) {}

  async handleError(error) {
    const alert = await this.alerts.create({
      header: 'An Error Has Occurred',
      message: error,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Restart',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }

}