import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// The possible log levels.
export enum LogLevel {
  None, // always keep at the top
  Error,
  Warning,
  Info,
  Debug,
  Trace,
  Entry,
  Exit,
  All // always keep this item last in the list
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  // Only show messages of the max level and up
  private currentMaxLogLevel;

  constructor() {
    // Default log level to info
    this.currentMaxLogLevel = LogLevel.Info;
  }

  // Debug level logging
  public debug(...objects: any[]) {
    this.log(console.debug, LogLevel.Debug, objects);
  }

  // Info level logging
  public info(...objects: any[]) {
    this.log(console.info, LogLevel.Info, objects);
  }

  // Warning level logging
  public warn(...objects: any[]) {
    this.log(console.warn, LogLevel.Warning, objects);
  }

  // Error level logging
  public error(...objects: any[]) {
    this.log(console.error, LogLevel.Error, objects);
  }

  // Trace level logging
  public trace(...objects: any[]) {
    this.log(console.trace, LogLevel.Trace, objects);
  }

  // Log level for entry into functions/methods 
  public entry(...objects: any[]) {
    this.log(this.entryLog, LogLevel.Entry, objects);
  }

  // Log level for exit from functions/methods 
  public exit(...objects: any[]) {
    this.log(this.exitLog, LogLevel.Exit, objects);
  }

  // Allow other classes to set the overall log level
  public setCurrentLogLevel(level) {
    this.currentMaxLogLevel = level;
  }

  // Special case for entry messages.  Use a right point emoji to see them 
  // clearly in the log
  private entryLog(message: string) {
    const rightPoint = String.fromCodePoint(0x1F449);
    //this only works in a browser    console.debug(rightPoint + "%c" + message, 'color: blue');
    console.debug(rightPoint + message);
  }

  // Special case for exit messages.  Use a left point emoji to see them 
  // clearly in the log
  private exitLog(message: string) {
    const leftPoint = String.fromCodePoint(0x1F448);
    console.debug(leftPoint + message);
  }

  // This is where the actual message is executed
  private log(func: Function, level: LogLevel, objects: any[]) {

    // Use the Ionic environment variable to determine if we are
    // in production mode
    const productionMode = environment.production;

    // If the overall log level is None, return immediately
    if (this.currentMaxLogLevel === LogLevel.None) {
      return;
    }

    // If we are in production mode, only apply error messages
    if (productionMode) {
      if (level === LogLevel.Error) {
        func.apply(console, objects);
        return;
      }
      // else not in production mode
    } else {
      // Only print messages higher than the overall log level
      if (level <= this.currentMaxLogLevel) {
        func.apply(console, objects);
      }
    }
  }
}
