import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { BuildInfo } from '../shared/types';

import { AppVersion } from '@ionic-native/app-version/ngx';
import { LoggerService } from './logger.service';

const { Device } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class MiscUtilitiesService {

  public isAnApp = false;
  public isAnIosDevice = false;

  private initializedFlag = false;

  private buildData: BuildInfo = { appName: "TBD", appVersion: "TBD", buildId: "0"};
  private buildDataRetrieved = false;

  constructor(
    public appVersion: AppVersion,
    private logger: LoggerService,
  ) {
    if (!this.initializedFlag) {
      this.initializeUtilities();
    }
  }

  public async initializeUtilities() {

    const info = await Device.getInfo();

    // Now check if the platform type is web vs the mobile types
    if (info.platform !== 'web') this.isAnApp = true;
    if (info.platform === 'ios') this.isAnIosDevice = true;


    this.initializedFlag = true;
  }

  public getBuildData(): Promise<BuildInfo> {
    this.logger.entry("misc-utilities.getBuildData()");
    return new Promise<BuildInfo>(resolve => {
      if (this.buildDataRetrieved) {
        this.logger.debug("getBuildData() data already retrieved!")
        resolve(this.buildData);
      }
      else {
        this.logger.debug("misc-utilities.getBuildData() getting app data...");
        // Check if we have already gotten the build data
        this.appVersion.getAppName().then((data) => {
          this.logger.debug("APPNAME: " + data);
          this.buildData.appName = data;
        });
        this.appVersion.getPackageName().then((data) => {
          this.logger.debug("PACKNAME: " + data);
        });
        this.appVersion.getVersionCode().then((data) => {
          this.logger.debug("VERCODE: " + data);
          this.buildData.buildId = data.toString();
        });
        this.appVersion.getVersionNumber().then((data) => {
          this.logger.debug("VERNAME: " + data);
          this.buildData.appVersion = data;
        });
        this.buildDataRetrieved = true;
        resolve(this.buildData);
      }
    })
  }

  // Method to determine whether program is running in an app
  // or in a browser
  public async isThisAnApp() {

    // Check what type of device this is
    const info = await Device.getInfo();

    // Now check if the platform type is web vs the mobile types
    if (info.platform === 'web') {
      return false;
    } else {
      return true;
    }
  }

  public async isThisAnIosDevice() {

    // get the device info
    const info = await Device.getInfo();

    if (info.platform === 'ios') return true;
    else return false;
  }

  // Helper method that allows a user to activate a promise alongside
  // a timeout.  If the timeout occurs first, the main promise function
  // will be rejected.
  public promiseTimeout = function (ms, promise) {
    let timeout = new Promise((resolve, reject) => {
      let id = setTimeout(() => {
        clearTimeout(id);
        reject('Timed out in ' + ms + 'ms.')
      }, ms)
    })
    return Promise.race([promise, timeout])
  }

}
